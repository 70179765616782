import '../scss/app.scss'; // Importing SCSS file


// let delete_buttons = document.querySelectorAll('.delete');
// class AdvertisementManager {
// constructor() {
// this.posts = document.querySelectorAll('.post');
 
// this.ads = [...document.querySelectorAll('.bluestreak-ad')];
// this.mobileAdsClone = this.cloneAds();
// this.tabletScreenSize = 1200;
// this.adsSidebar = document.querySelector('.advertisment-sidebar');
// this.currentScreenSize = window.innerWidth;
// this.is_front_page = blue_streak.is_front_page === '1' ? true : false;
// this.is_single_page = blue_streak.is_single === '1' ? true : false;
// this.images = false; 
// this.isAdmin = blue_streak.is_admin === '1' ? true : false;
 
 
//  if(this.is_single_page) {
//     this.images = [...document.querySelectorAll('.post img')].map(img => img.closest('.wp-caption'));
    
//  }


// this.handleResize(); // Check screen size initially.
// this.events();
// }

// events(){
//     window.addEventListener('resize', this.handleResize.bind(this));
    
//     if(!this.isAdmin) {
//     // loop through all the ads and add event listener
//     this.ads.forEach(ad => {
//         ad.addEventListener('click', this.ad_click_counter);
//     });

//     // loop through all mobile ads and add event listener
//     this.mobileAdsClone.forEach(ad => {
//         ad.addEventListener('click', this.ad_click_counter);
//     });
// }
// }
// cloneAds() {

   
// return this.ads.map((ad) => {
//  const clone = ad.cloneNode(true);
//  clone.classList.add('mobile');
// //  if clone has featured class, remove it
// // clone.classList.contains('featured') && clone.classList.remove('featured');
//  return clone;
// });
// }

// addAdvertisementAfterEveryNPosts(n = 3) {
//  let correct_array = this.images  ? this.images : this.posts;
 
// if (this.currentScreenSize <= this.tabletScreenSize) {
//  correct_array.forEach((post, index) => {
    
//    if (index % n === 0 && index !== 0) {
     
//      const adIndex = index / n - 1;
   
//      if (adIndex < this.mobileAdsClone.length) {
//        const ad = this.mobileAdsClone[adIndex];
//        post.after(ad);
     
//      }
//    }
//  });
// }  
// }

// handleResize() {
// this.currentScreenSize = window.innerWidth;
 
// if (this.currentScreenSize < this.tabletScreenSize) {
   
//  this.hideAdsSidebar();
//  this.addAdvertisementAfterEveryNPosts();
// } else {
   
//  this.showAdsSidebar();
//  this.removeMobileAds(); // Fixed the issue here
// }
// }

// removeMobileAds() {
   
// this.mobileAdsClone.forEach((ad) => {
//  ad.remove();
// });
// }

// hideAdsSidebar() {
// if (this.adsSidebar) {
//  this.adsSidebar.style.display = 'none';
// }
// }

// showAdsSidebar() {
// if (this.adsSidebar) {
//  this.adsSidebar.style.display = '';
// }
// }

// async   ad_click_counter  (e)  {
//     console.log('counter');
   
//     e.preventDefault();
     
    
//     let ad = e.target.closest('.bluestreak-ad');
//     let id = +ad.id;
   
//     try {
//         let url = blue_streak.ajax_url + 'bluestreak/v1/click';
         
//         let res  = await fetch(url, {
//             headers: {
//                 'Content-Type': 'application/json',
//                 'X-WP-Nonce': blue_streak.nonce
//             },
//             method: 'POST',
//             body: JSON.stringify({
//              id
//             })
//         });
//         let data = await res.json();

//         if(data?.success) {
//             // click the link
//             // href 
//             let href = ad.href;
//             // open in new tab
//             window.open(href, '_blank');
//         } else {
//             alert(data.message);
//             throw new Error( data.message);
//         }
//     } catch (error) {
//         console.log(error);
//         throw error;
        
//     }
// }
// }

// const adManager = new AdvertisementManager();


// // //    bluestreak-ad 
// // let blueStreakAds = document.querySelectorAll('.bluestreak-ad');
// // delete_buttons.forEach(button => {
// //    button.addEventListener('click', (e) => {
// //        e.preventDefault();
// //        let confirm = window.confirm('Are you sure you want to delete this ad?');
// //        if (confirm) {
// //            // click the link
// //            window.location.href = button.href;

// //        }
// //    });
// // });
 
 
 
// // 


 
let blogRollItem = document.querySelector('.xoxo.blogroll');

// Check if the element exists
if (blogRollItem) {
    // Get all li elements inside the selected ul
    let listItems = blogRollItem.querySelectorAll('li');

    // Loop through each li element
    listItems.forEach(function(item) {
        // Loop through each child node of the li element
        item.childNodes.forEach(function(child) {
            // Check if the child is a text node
            if (child.nodeType === 3) {
                // Remove the text node
                item.removeChild(child);
            }
        });
    });
} else {
    console.log('No elements with class xoxo blogroll found');
}
